<template>
  <div>
    <b-row>
      <b-col xl="6" md="6">
        <select-card />
      </b-col>
    </b-row>
    <b-row>
      <b-col xl="12" md="12">
        <lisans-sifre-bilgi />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import store from '@/store';

import SelectCard from './home-components/SelectCard.vue';
import LisansSifreBilgi from './home-components/LisansSifreBilgi.vue';

export default {
  components: {
    SelectCard,
    LisansSifreBilgi,
  },
  methods: {
    fetchHomeInitObject() {
      store.dispatch('homeCustomer/fetchHomeInitObject');
    },
  },
  mounted() {
    this.fetchHomeInitObject();
  },
}
</script>
