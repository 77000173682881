<template>
  <b-row>
    <b-col cols="12" xl="6" lg="6" md="12">
      <b-card class="custom-select-card"
          @click="$router.push({ name: 'customer-db-kullanici' })">
        <b-media no-body>
          <b-media-aside class="mr-2">
            <b-avatar
              size="48"
              variant="light-primary">
              <feather-icon
                size="24"
                icon="DatabaseIcon"
              />
            </b-avatar>
          </b-media-aside>
          <b-media-body class="my-auto">
            <h5 class="font-weight-bolder mb-0">
              Veri Tabanı Kullanıcıları
            </h5>
            <b-card-text class="font-small-3 mb-0">
              {{ homeInitObject.activeDbKullaniciSayisi }}
            </b-card-text>
          </b-media-body>
        </b-media>
      </b-card>
    </b-col>
    <b-col cols="12" xl="6" lg="6" md="12">
      <b-card class="custom-select-card"
          @click="$router.push({ name: 'customer-webapi-kullanici' })">
        <b-media no-body>
          <b-media-aside class="mr-2">
            <b-avatar
              size="48"
              variant="light-success">
              <feather-icon
                size="24"
                icon="CloudIcon"
              />
            </b-avatar>
          </b-media-aside>
          <b-media-body class="my-auto">
            <h5 class="font-weight-bolder mb-0">
              WebApi Kullanıcıları
            </h5>
            <b-card-text class="font-small-3 mb-0">
              {{ homeInitObject.activeWebApiKullaniciSayisi }}
            </b-card-text>
          </b-media-body>
        </b-media>
      </b-card>
    </b-col>
    <b-col cols="12" xl="6" lg="6" md="12">
      <b-card class="custom-select-card"
          @click="$router.push({ name: 'customer-siparis' })">
        <b-media no-body>
          <b-media-aside class="mr-2">
            <b-avatar
              size="48"
              variant="light-danger">
              <feather-icon
                size="24"
                icon="GridIcon"
              />
            </b-avatar>
          </b-media-aside>
          <b-media-body class="my-auto">
            <h5 class="font-weight-bolder mb-0">
              Siparişlerim
            </h5>
            <b-card-text class="font-small-3 mb-0" />
          </b-media-body>
        </b-media>
      </b-card>
    </b-col>
    <b-col cols="12" xl="6" lg="6" md="12">
      <b-card class="custom-select-card"
          @click="$router.push({ name: 'customer-cariekstra' })">
        <b-media no-body>
          <b-media-aside class="mr-2">
            <b-avatar
              size="48"
              variant="light-warning">
              <feather-icon
                size="24"
                icon="CpuIcon"
              />
            </b-avatar>
          </b-media-aside>
          <b-media-body class="my-auto">
            <h5 class="font-weight-bolder mb-0">
              Cari Ekstre
            </h5>
            <b-card-text class="font-small-3 mb-0" />
          </b-media-body>
        </b-media>
      </b-card>
    </b-col>
    <b-col cols="12" xl="6" lg="6" md="12">
      <b-card class="custom-select-card"
          @click="$router.push({ name: 'customer-lisanssifrebilgi' })">
        <b-media no-body>
          <b-media-aside class="mr-2">
            <b-avatar
              size="48"
              variant="light-info">
              <feather-icon
                size="24"
                icon="LockIcon"
              />
            </b-avatar>
          </b-media-aside>
          <b-media-body class="my-auto">
            <h5 class="font-weight-bolder mb-0">
              Lisanslar
            </h5>
            <b-card-text class="font-small-3 mb-0" />
          </b-media-body>
        </b-media>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
export default {
  computed: {
    homeInitObject() {
      return this.$store.getters['homeCustomer/getHomeInitObject'];
    },
  },
};
</script>

<style scoped>
.custom-select-card {
  cursor: pointer;
}

.custom-select-card:hover {
    color:#ffffff;
    background-color: #7367f0;
}

.custom-select-card:hover h5 {
    color:#ffffff;
}

.custom-select-card:hover .b-avatar.badge-light-primary {
  background-color: rgb(233 232 241 / 59%);
  color: #f6f6f9;
}

.custom-select-card:hover .b-avatar.badge-light-success {
  background-color: rgb(233 232 241 / 59%);
  color: #f6f6f9;
}

.custom-select-card:hover .b-avatar.badge-light-danger {
  background-color: rgb(233 232 241 / 59%);
  color: #f6f6f9;
}

.custom-select-card:hover .b-avatar.badge-light-info {
  background-color: rgb(233 232 241 / 59%);
  color: #f6f6f9;
}

@media screen and (max-width: 1584px) {
  .custom-select-card .card-body {
    padding: 0.9rem;
  }
  .custom-select-card h5 {
    font-size: 12px;
  }
}
</style>
