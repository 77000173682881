<template>
  <b-row>
    <b-col cols="12" xl="12">
      <b-card class="card-company-table" title="Lisanslar">
        <b-table
          :items="lisansSifreBilgis"
          :fields="tableColumns"
          class="mb-0"
          responsive
          show-empty
          empty-text="Hiçbir eşleşen kayıt bulunamadı"
        >
          <!-- Urun -->
          <template #cell(urun)="data">
            <div class="d-flex align-items-center">
              <b-avatar class="mr-1" variant="light-primary">
                <feather-icon size="18" icon="MonitorIcon" />
              </b-avatar>
              <span>{{ data.item.urun }}</span>
            </div>
          </template>

          <!-- Lisans -->
          <template #cell(views)="data">
            <div class="d-flex flex-column">
              <span class="font-weight-bolder mb-25">{{
                data.item.sLisansNo
              }}</span>
            </div>
          </template>

          <!-- Lisans -->
          <template #cell(dtLisansBitisTarihi)="data">
            <div class="d-flex flex-column">
              <span class="font-weight-bolder mb-25">{{
                datetolocale(data.item.dtLisansBitisTarihi)
              }}</span>
            </div>
          </template>

          <!-- Durum -->
          <template #cell(isLisansGecerli)="data">
            <b-badge
              pill
              :variant="`light-${resolveStatusVariant(
                data.item.isLisansGecerli
              )}`"
              class="text-capitalize"
            >
              <feather-icon
                :icon="resolveStatusIcon(data.item.isLisansGecerli)"
                size="18"
                class="mr-50"
                :class="`text-${resolveStatusVariant(
                  data.item.isLisansGecerli
                )}`"
              />
              {{ resolveStatusName(data.item.isLisansGecerli) }}
            </b-badge>
          </template>
        </b-table>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { datetolocale } from '@/@core/utils/filter'

export default {
  data() {
    return {
      tableColumns: [
        { label: 'Ürün', key: 'urun', sortable: true },
        { label: 'Lisans No', key: 'sLisansNo', sortable: true },
        { label: 'Bitiş Tarih', key: 'dtLisansBitisTarihi', sortable: true },
        { label: 'Durum', key: 'isLisansGecerli', sortable: true, class: 'text-center' },
      ],
    }
  },
  computed: {
    lisansSifreBilgis() {
      return this.$store.getters['lisanssifrebilgiCustomer/getLisansSifreBilgis'];
    },
  },
  methods: {
    datetolocale,
    fetchLisansSifreBilgis() {
      this.$store.dispatch('lisanssifrebilgiCustomer/fetchLisansSifreBilgis');
    },
    resolveStatusIcon(status) {
      if (status === false) return 'XIcon'
      if (status === true) return 'UserCheckIcon'
      return 'UserIcon'
    },
    resolveStatusName(status) {
      if (status === false) return 'GEÇERSİZ'
      if (status === true) return 'GEÇERLİ'
      return 'TANIMLI DEĞİL'
    },
    resolveStatusVariant(status) {
      if (status === false) return 'warning'
      if (status === true) return 'success'
      return 'primary'
    },
  },
  mounted() {
    this.fetchLisansSifreBilgis();
  },
};
</script>
